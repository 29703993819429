<template>
    <div class="p-2 d-flex flex-column flex-fill">

        <div class="mb-2">
            <div class="">
                <router-link :to="`/shelter/donate/${shelter_id}`" class="h4 mb-0 text-white d-flex align-items-center" style="">
                    <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                    Back
                </router-link>
            </div>

            <h1 class="font-weight-bolder text-white text-center mt-5">Thank You!</h1>
            <div class="h3 font-weight-bold mt-1 text-white text-center">
                Your donation has been confirm.
            </div>

            <!-- <div class="h3 font-weight-bold mt-5 text-white text-center">
                Here Are Your Receipt
            </div> -->

            <div class="d-flex mt-2 mb-1 align-items-center justify-content-between" style="gap:10px">
                <div class="h3 font-weight-bolder text-white mb-0">
                    {{input_data.shd_title}}
                </div>
                <div class="h3 font-weight-bolder text-white mb-0 d-flex align-items-center">
                    <i class="bx bx-dollar" style="font-size: 20px;" ></i> {{ input_data.nominal }}
                </div>
            </div> 

            <div> 
                <p class="text-white">
                    {{ input_data.shd_details }}
                </p>
            </div>  

            <div class="h3 mt-3 font-weight-bold text-white mb-0">
                Payment
            </div>
            <p class="text-white mt-1">For payment you can call the shelter directly.</p>

        </div>

        <div class="mt-auto">
            <hr style="border-color: #FFFFFF;" />
            <div class="d-flex mt-2 mb-1 align-items-center justify-content-between" style="gap:10px">
                <div class="h3 font-weight-bolder text-white mb-0">
                    Total
                </div>
                <div class="h3 font-weight-bolder text-white mb-0 d-flex align-items-center">
                    <i class="bx bx-dollar" style="font-size: 20px;" ></i> {{ input_data.nominal }}
                </div>
            </div>

            <div class="" style="margin-top: .5em;">
                <div class="d-flex align-items-center justify-content-between" style="gap:10px"> 
                    <div>
                        <router-link :to="`/shelter/donate/${shelter_id}`" class="text-white d-flex align-items-center"
                            style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 10px;">
                            <div class="h4 text-white mb-0 ml-auto">Back To Home</div>
                        </router-link>
                    </div>
                    <!-- <div>
                        <div class="text-white d-flex align-items-center"
                            style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 10px;">
                            <div class="h4 text-white mb-0 ml-auto">Print Receipt</div>
                        </div>
                    </div> -->
                </div>
            </div>

        </div>

    </div>
</template>

<script>

import store from '@/store'

export default {
    mounted(){
        this.input_data = { ...this.$route.query}
    }, 
    data(){
        return {
            input_data: {},
            shelter_id: this.$route.params.id
        }
    }
}

</script>

<style>
input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
</style>